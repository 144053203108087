import React from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import Grid from "@material-ui/core/Grid";

import { SvgIcon } from "@material-ui/core";

import { useStyles } from "./Header.styles";
import { ReactComponent as mafLogoIcon } from "./../../assets/icons/logo-icon.svg";
import { ReactComponent as HyperMaxLogoIcon } from "./../../assets/icons/HyperMaxLogoIcon.svg";

import { AppState } from "../../config/redux/reducers";
import { isCountryJordan } from "../../utils/helpers.utils";

const Header = () => {
  const classes = useStyles();
  const { loading, consignmentData } = useSelector((state: AppState) => state.common);

  return (
    <>
      {loading ? (
        <></>
      ) : (
        <Grid container className={isCountryJordan(consignmentData) ? classes.hyperMaxRoot : classes.root}>
          <Grid className="headerItem left" item>
            {isCountryJordan(consignmentData) ? (
              <SvgIcon className="logoIcon" viewBox="0 0 313 327" component={HyperMaxLogoIcon} />
            ) : (
              <SvgIcon className="logoIcon" viewBox="0 0 138 34" component={mafLogoIcon} />
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default withRouter(Header);
