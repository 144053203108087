/* eslint-disable  @typescript-eslint/no-explicit-any */
/**
 * Check whether the given variable is undefined or not.
 * If the given variable is undefined, returns true.
 * @param {*} target - Target for checking
 * @returns {boolean} Is undefined?
 */
import moment from "moment";
export const isUndefined = (target: any): boolean => target === undefined;

/**
 * Check whether the given variable is null or not.
 * If the given variable(arguments[0]) is null, returns true.
 * @param {*} target - Target for checking
 * @returns {boolean} Is null?
 */
export const isNull = (target: any): boolean =>
  target === null || target === "";

/**
 * Check whether the given variable is existing or not.
 * If the given variable is not null and not undefined, returns true.
 * @param {*} target - Target for checking
 * @returns {boolean} Is existy?
 */
export const isExisty = (target: any): boolean =>
  !isUndefined(target) && !isNull(target);





export const getDayAndMonthName = (date: any, timezone: string,) => {
  if(date){
    if(timezone){
      return new Date(date).toLocaleDateString('en-GB', {timeZone : timezone, day: 'numeric', weekday: 'long', month: 'long' })
    }else{
      return new Date(date).toLocaleDateString('en-GB', {day: 'numeric', weekday: 'long', month: 'long' })
    }
  }
  return '';
}

export const getDateStringFromUTC = (date: any, timezone: string,) => {
  if(date){
    if(timezone){
      
      return new Date(new Date(date).toLocaleString('en-US',{timeZone: timezone}))
    }else{
      return new Date(date).toLocaleDateString('en-US')
    }
  }
  return '';
}

export const getTimeStringFromUTC = (date: any, timezone: string, hour12 = false) => {
  if(date){
    if(timezone){
      return new Date(date).toLocaleTimeString('en-GB', {hour12: hour12, hour: 'numeric', minute: 'numeric', timeZone : timezone})
    }else{
      return new Date(date).toLocaleTimeString('en-GB', {hour12: hour12, hour: 'numeric', minute: 'numeric'})
    }
  }
  return '';
}

export const getFormatedDate = (date: any) => {
  if(date){
   return moment(date).format('hh:mm a');
  }
  return '';
}

export const getLocaltime = () => {
  const DATE_FORMAT_BACKEND =  'YYYY-MM-DDTHH:mm:ss';
  return moment().format(DATE_FORMAT_BACKEND);
}

export const isCountryJordan = (consignmentData : any) => {
  return consignmentData?.deliveryInformation?.customerContactInfo?.address?.countryIsoCode && consignmentData?.deliveryInformation?.customerContactInfo?.address?.countryIsoCode === "JO";
};