import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useLocation } from "react-router-dom";

import Header from "./common/Header";
import Routes from "./routes";
import AppConstants from "./constants/";

const App = () => {
  const [isNonFood, setIsNonFood] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (AppConstants.ROUTES.TRACK_ORDER_MKP === location?.pathname) {
      setIsNonFood(true);
    }
  }, []);

  return (
    <>
      {!isNonFood ? <Header /> : null}
      <main className={`routesContainer`}>
        <Routes />
      </main>
    </>
  );
};

export default withRouter(App);
