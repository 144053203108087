import AppConstants from "constants/AppConstants";
import CommonConstants from "constants/CommonConstats";
interface statusProps {
  WAITING: string;
  READY: string;
  EXPORTED: string;
  PICKING_STARTED: string;
  PACKING_COMPLETE: string;
  READY_TO_DISPATCH: string;
  DISPATCHED: string;
  PENDING_DELIVERY: string;
  DELIVRED: string;
  DELIVERED: string;
  DELIVERY_FAILED: string;
  CANCELLED: string;
  PARTIALLY_DELIVERED: string;
}

export const getLanguageFromUrl = (urlParams: any) => {
  const lang = urlParams.get("language");
  if (lang && (lang === "en" || lang === "ar" || lang === "ka")) {
    return lang;
  }
  return "en";
};
export const getStatusClass = (status: string, defaultLanguage: any) => {
  let statusClass = "Order placed";
  if (status) {
    switch (status) {
      case "WAITING":
        statusClass = defaultLanguage.DELIVERY_STATUS.ORDER_PLACED;
        break;
      case "READY":
        statusClass = defaultLanguage.DELIVERY_STATUS.ORDER_PLACED;
        break;
      case "EXPORTED":
        statusClass = defaultLanguage.DELIVERY_STATUS.ORDER_PLACED;
        break;
      case "PICKING_STARTED":
        statusClass = defaultLanguage.DELIVERY_STATUS.ORDER_BEING_PREPARED;
        break;
      case "PACKING_COMPLETE":
        statusClass = defaultLanguage.DELIVERY_STATUS.ORDER_BEING_PREPARED;
        break;
      case "READY_TO_DISPATCH":
        statusClass = defaultLanguage.DELIVERY_STATUS.ORDER_BEING_PREPARED;
        break;
      case "DISPATCHED":
        statusClass = defaultLanguage.DELIVERY_STATUS.ORDER_ON_THE_WAY;
        break;
      case "PENDING_DELIVERY":
        statusClass = defaultLanguage.DELIVERY_STATUS.ORDER_ON_THE_WAY;
        break;
      case "DELIVRED":
        statusClass = defaultLanguage.DELIVERY_STATUS.ORDER_DELIVERED;
        break;
      case "DELIVERED":
        statusClass = defaultLanguage.DELIVERY_STATUS.ORDER_DELIVERED;
        break;
      case "DELIVERY_FAILED":
        statusClass = defaultLanguage.DELIVERY_STATUS.DELIVERY_FAILED;
        break;
      case "CANCELLED":
        statusClass = defaultLanguage.DELIVERY_STATUS.ORDER_CANCELLED;
        break;
      default:
        statusClass = defaultLanguage.DELIVERY_STATUS.ORDER_PLACED;
        break;
    }
  }
  return statusClass;
};

export const displayJordanStatusMsg = (language: string) => {
  return {
    WAITING: AppConstants[language].JORDAN_DELIVERY_OMS_STATUS_MSGS.WAITING,
    READY: AppConstants[language].JORDAN_DELIVERY_OMS_STATUS_MSGS.READY,
    EXPORTED: AppConstants[language].JORDAN_DELIVERY_OMS_STATUS_MSGS.EXPORTED,
    PICKING_STARTED: AppConstants[language].JORDAN_DELIVERY_OMS_STATUS_MSGS.PICKING_STARTED,
    PACKING_COMPLETE: AppConstants[language].JORDAN_DELIVERY_OMS_STATUS_MSGS.PACKING_COMPLETE,
    READY_TO_DISPATCH: AppConstants[language].JORDAN_DELIVERY_OMS_STATUS_MSGS.READY_TO_DISPATCH,
    DISPATCHED: AppConstants[language].JORDAN_DELIVERY_OMS_STATUS_MSGS.DISPATCHED,
    PENDING_DELIVERY: AppConstants[language].JORDAN_DELIVERY_OMS_STATUS_MSGS.PENDING_DELIVERY,
    DELIVRED: AppConstants[language].JORDAN_DELIVERY_OMS_STATUS_MSGS.DELIVRED,
    DELIVERED: AppConstants[language].JORDAN_DELIVERY_OMS_STATUS_MSGS.DELIVRED,
    DELIVERY_FAILED: AppConstants[language].JORDAN_DELIVERY_OMS_STATUS_MSGS.DELIVERY_FAILED,
    CANCELLED: AppConstants[language].JORDAN_DELIVERY_OMS_STATUS_MSGS.CANCELLED,
    PARTIALLY_DELIVERED: AppConstants[language].JORDAN_DELIVERY_OMS_STATUS_MSGS.DELIVRED,
  };
};

export const displayStatusMsg = (language: string) => {
  return {
    WAITING: AppConstants[language].DELIVERY_OMS_STATUS_MSGS.WAITING,
    READY: AppConstants[language].DELIVERY_OMS_STATUS_MSGS.READY,
    EXPORTED: AppConstants[language].DELIVERY_OMS_STATUS_MSGS.EXPORTED,
    PICKING_STARTED: AppConstants[language].DELIVERY_OMS_STATUS_MSGS.PICKING_STARTED,
    PACKING_COMPLETE: AppConstants[language].DELIVERY_OMS_STATUS_MSGS.PACKING_COMPLETE,
    READY_TO_DISPATCH: AppConstants[language].DELIVERY_OMS_STATUS_MSGS.READY_TO_DISPATCH,
    DISPATCHED: AppConstants[language].DELIVERY_OMS_STATUS_MSGS.DISPATCHED,
    PENDING_DELIVERY: AppConstants[language].DELIVERY_OMS_STATUS_MSGS.PENDING_DELIVERY,
    DELIVRED: AppConstants[language].DELIVERY_OMS_STATUS_MSGS.DELIVRED,
    DELIVERED: AppConstants[language].DELIVERY_OMS_STATUS_MSGS.DELIVRED,
    DELIVERY_FAILED: AppConstants[language].DELIVERY_OMS_STATUS_MSGS.DELIVERY_FAILED,
    CANCELLED: AppConstants[language].DELIVERY_OMS_STATUS_MSGS.CANCELLED,
    PARTIALLY_DELIVERED: AppConstants[language].DELIVERY_OMS_STATUS_MSGS.DELIVRED,
  };
};

export const getStatusMsg = (status: string, statusFFStatus: string, language: string, countryIsoCode?: string) => {
  let msg = AppConstants[language].DELIVERY_OMS_STATUS_MSGS.READY;
  if (language && language === "ar" && countryIsoCode && countryIsoCode === "JO") {
    msg = AppConstants[language].JORDAN_DELIVERY_OMS_STATUS_MSGS.READY;
  }
  const statusMsgs: statusProps = language && language === "ar" && countryIsoCode && countryIsoCode === "JO" ? displayJordanStatusMsg(language) : displayStatusMsg(language);
  if (statusFFStatus && (statusFFStatus === CommonConstants.FF_STATUS.DELIVERED || statusFFStatus === CommonConstants.FF_STATUS.PARTIALLY_DELIVERED)) {
    msg = statusMsgs[statusFFStatus as keyof statusProps];
  } else {
    msg = statusMsgs[status as keyof statusProps];
  }
  return msg;
};

export const getStatusMobile = (status: string, language: string, defaultLanguage: any, statusFFStatus: string) => {
  if (statusFFStatus === CommonConstants.FF_STATUS.DELIVERED || statusFFStatus === CommonConstants.FF_STATUS.PARTIALLY_DELIVERED) return AppConstants[language].DELIVERY_STATUS.ORDER_DELIVERED;
  else if (status === defaultLanguage.DELIVERY_STATUS.ORDER_PLACED) return AppConstants[language].DELIVERY_STATUS.ORDER_PLACED;
  else if (status === defaultLanguage.DELIVERY_STATUS.ORDER_BEING_PREPARED) return AppConstants[language].DELIVERY_STATUS.ORDER_BEING_PREPARED;
  else if (status === defaultLanguage.DELIVERY_STATUS.ORDER_ON_THE_WAY) return AppConstants[language].DELIVERY_STATUS.ORDER_ON_THE_WAY;
  else if (status === defaultLanguage.DELIVERY_STATUS.ORDER_DELIVERED) return AppConstants[language].DELIVERY_STATUS.ORDER_DELIVERED;
  else if (status === defaultLanguage.DELIVERY_STATUS.ORDER_CANCELLED) return AppConstants[language].DELIVERY_STATUS.ORDER_CANCELLED;
};

export const getDeliverdStatus = (status: string, statusFFStatus: string, defaultLanguage: any) => {
  return status === defaultLanguage.DELIVERY_STATUS.ORDER_DELIVERED || statusFFStatus === CommonConstants.FF_STATUS.PARTIALLY_DELIVERED || statusFFStatus === CommonConstants.FF_STATUS.DELIVERED;
};
