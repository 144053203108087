import moment from "moment";
import networkHandler from "../../../network/networkHandler";

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { EApiMiddlewareMethods } from "../../../network/networkHandler.types";
import { CommonState } from "./commonSlice.types";
import { getLocaltime } from "../../../utils/helpers.utils";

export const updateMapDirections = createAsyncThunk("Common/MapDirectionsUpdate", (directions: any) => {
  return directions;
});

export const updateDuration = createAsyncThunk("Common/updateDuration", (directions: any) => {
  return directions;
});

export const updateHubLocation = createAsyncThunk("Common/updateHubLocation", (hubLocation: any) => {
  return hubLocation;
});

export const fetchOrderStatus = createAsyncThunk("Common/FetchOrderStatus",async ({consignmentCode, carrier, source = undefined}: {consignmentCode: any; carrier: string, source?: string},{ rejectWithValue }) => {
    try {
      const request = {
        url: `tracking/orderStatus`,
        method: EApiMiddlewareMethods.POST,
        data: {consignmentCode : consignmentCode, carrier: carrier},
      } as any;
      const { data } = await networkHandler(request, false, source);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDriverLiveLocation = createAsyncThunk(
  "Common/FetchDriverLiveLocation",
  async (
    {
      carrier,
      consignmentCode,
      driverId,
      countryIsoCode,
      category
    }: {
      carrier: string;
      consignmentCode: string;
      driverId: string;
      countryIsoCode: string;
      category: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `track/get-driver-location-by-key`,
        method: EApiMiddlewareMethods.POST,
        data: {carrier : carrier, consignmentCode : consignmentCode, driverId : driverId, countryIsoCode : countryIsoCode, category : category},
      } as any;
      const { data } = await networkHandler(request, false);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchHubLocation = createAsyncThunk(
  "Common/FetchHubLocation",
  async (
    {
      hubCode,
      carrier,
      countryIsoCode,
    }: {
      hubCode: string;
      carrier: string;
      countryIsoCode: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `track/get-hub-location-by-hub-code`,
        method: EApiMiddlewareMethods.POST,
        data: {hubCode : hubCode, carrier : carrier, countryIsoCode : countryIsoCode},
      } as any;
      const { data } = await networkHandler(request, false);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState: CommonState = {
  loading: false,
  error: "",
  errorCode: "",
  consignmentData: {},
  driverLocation: {},
  wayPointsInfo: {},
  serviceTime: 0,
  hubLocation: {},
  mapRouteDirections: {},
  durations: 0,
  orderArrivalTime: 0,
  polylinePointsOFD: '',
  isTechIssue: false
}

const commonSlice = createSlice({
  name: "Common",
  initialState,
  reducers: {},
  extraReducers: (builders) => {
    builders
      .addCase(fetchOrderStatus.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchOrderStatus.fulfilled, (state, action) => {
        const { payload, meta: { arg } } = action;      
        state.loading = false;
        state.consignmentData = payload?.data?.orderInfo;
        if(Object.keys(payload?.data?.driverInfo).length){
         if(payload?.data && Object.keys(payload?.data?.driverInfo?.currentLocation).length === 0){
            state.isTechIssue = true;
          }
          else{
            state.isTechIssue = false;
            if(arg?.source){
              state.driverLocation = {"driverId" : payload?.data?.driverInfo?.driverId, "currentLocation" : payload?.data?.driverInfo?.currentLocation};
            }
            state.wayPointsInfo = payload?.data?.driverInfo.locations ? payload.data.driverInfo.locations : {};
            state.serviceTime = payload?.data?.driverInfo?.serviceTime ? payload.data.driverInfo.serviceTime : 0;
          }
        }
        if(Object.keys(payload.data?.directionInfo)){
           state.polylinePointsOFD= payload?.data?.directionInfo?.polylinePoints 
          if(payload?.data?.directionInfo?.orderArrivalTime){
            const arrivalTime = payload?.data?.directionInfo?.orderArrivalTime;
            const timezone = payload?.data?.directionInfo?.timezone;
            const formattedDate = new Date(new Date(arrivalTime).toLocaleString('en-US',{timeZone: timezone}));
            const formattedTime = moment(formattedDate).format('hh:mm a');
            if(arg?.source){
              state.orderArrivalTime = formattedTime; 
            }else if(payload?.data?.orderInfo?.deliveryInformation?.deliverySlot?.endTime) {
              const formattedEndTime = moment(payload?.data?.orderInfo?.deliveryInformation?.deliverySlot?.endTime).format('hh:mm a');
              state.orderArrivalTime = formattedEndTime;
              if(formattedDate && moment(formattedDate).isValid() && moment(formattedDate).isAfter(payload?.data?.orderInfo?.deliveryInformation?.deliverySlot?.endTime)){
                const localTime = getLocaltime();
                if(localTime && moment(localTime).isValid() && moment(localTime).isBefore(payload?.data?.orderInfo?.deliveryInformation?.deliverySlot?.endTime)){
                  state.orderArrivalTime = formattedEndTime; 
                }else{
                  state.orderArrivalTime = formattedTime;
                }
              }          
            }
          }
        }
      })
      .addCase(fetchOrderStatus.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchDriverLiveLocation.pending, (state) => {
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchDriverLiveLocation.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.errorCode = "";
        state.error = "";
        if(Object.keys(payload).length === 0){
          state.isTechIssue = true;
        }else if(payload && Object.keys(payload.currentLocation).length === 0){
          state.isTechIssue = true;
        }else{
          state.isTechIssue = false;
          state.driverLocation = {"driverId" : payload.driverId, "currentLocation" : payload.currentLocation};
          state.wayPointsInfo = payload.locations ? payload.locations : {};
          state.serviceTime = payload.serviceTime ? payload.serviceTime : 0;
        }
      })
      .addCase(fetchDriverLiveLocation.rejected, (state) => {
        state.errorCode = "";
        state.error = "";
        state.isTechIssue = true;
      })
      .addCase(fetchHubLocation.pending, (state) => {
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchHubLocation.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.errorCode = "";
        state.error = "";
        state.hubLocation = payload;
      })
      .addCase(fetchHubLocation.rejected, (state) => {
        state.errorCode = "";
        state.error = "";
      })
      .addCase(updateHubLocation.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.errorCode = "";
        state.error = "";
        state.hubLocation = payload;
      })
      .addCase(updateMapDirections.fulfilled, (state, action) => {
        const { payload } = action;
        state.mapRouteDirections = payload;
      })
      .addCase(updateDuration.fulfilled, (state, action) => {
        const { payload } = action;
        state.durations = payload;
      });
  }
});

export default commonSlice.reducer;