import React, { useCallback, useEffect, useRef, useState, useMemo } from "react";
import moment from "moment";
import qs from "qs";
import useLanguageHook from "hooks/useLanguage.hook";

import { Grid, SvgIcon, Typography } from "@material-ui/core";

import { ReactComponent as deliveryTimelineIcon } from "./../assets/icons/delivery-timeline-icon.svg";
import { ReactComponent as deliveredIcon } from "./../assets/icons/delivered-icon.svg";
import { ReactComponent as deliveryFailedIcon } from "./../assets/icons/delivery-failed-icon.svg";
import { ReactComponent as technicalIssueIcon } from "./../assets/icons/technical-issue-icon.svg";
import { ReactComponent as cancelledIcon } from "./../assets/icons/cancelled-icon.svg";
import { ReactComponent as orderPlacedIcon } from "./../assets/icons/order-placed-icon.svg";
import { ReactComponent as orderBeingPrepared } from "./../assets/icons/order-prepairing-icon.svg";
import { ReactComponent as orderOntheWayIcon } from "./../assets/icons/order-on-the-way-icon.svg";
import { ReactComponent as orderDeliveredIcon } from "./../assets/icons/order-delivered-icon.svg";
// HyperMax
import { ReactComponent as hyperMaxDeliveredIcon } from "./../assets/icons/hyper-max-delivered-icon.svg";
import { ReactComponent as hyperMaxCancelledIcon } from "./../assets/icons/hyper-max-cancelled-icon.svg";
import { ReactComponent as hyperMaxDeliveryFailedIcon } from "./../assets/icons/hyper-max-delivery-failed-icon.svg";
import { ReactComponent as hyperMaxTechnicalIssueIcon } from "./../assets/icons/hyper-max-technical-issue-icon.svg";
import { ReactComponent as hyperMaxOrderPlacedIcon } from "./../assets/icons/hyper-max-order-placed-icon.svg";
import { ReactComponent as hyperMaxOrderBeingPrepared } from "./../assets/icons/hyper-max-order-prepairing-icon.svg";
import { ReactComponent as hyperMaxOrderOntheWayIcon } from "./../assets/icons/hyper-max-order-on-the-way-icon.svg";
import { ReactComponent as hyperMaxOrderDeliveredIcon } from "./../assets/icons/order-delivered-icon.svg";

import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./OrderTracking.styles";
import { AppState } from "config/redux/reducers";
import { fetchOrderStatus, fetchDriverLiveLocation, updateHubLocation } from "config/redux/common/commonSlice";
import { getDateStringFromUTC, getFormatedDate, isCountryJordan } from "utils/helpers.utils";
import { getLanguageFromUrl, getStatusClass, getStatusMobile, getStatusMsg, getDeliverdStatus } from "../utils/orderTracking.util";

import hubIcon from "./../assets/icons/hub-icon.svg";
import customerIcon from "./../assets/icons/customer-icon.svg";
import trackerIcon from "./../assets/icons/tracker-icon.svg";

import AppConstants from "constants/AppConstants";
import CommonConstants from "constants/CommonConstats";
import MapContainer from "common/GoogleMapContainer";
import Loader from "../common/Loader";

// HyperMax
import hyperMaxHubIcon from "./../assets/icons/hyper-max-hub-icon.svg";
import hyperMaxCustomerIcon from "./../assets/icons/hyper-max-customer-icon.svg";
import hyperMaxTrackerIcon from "./../assets/icons/hyper-max-tracker-icon.svg";

interface OrderTrackingProps {
  location?: any;
  match?: any;
}

interface omsStatusProps {
  statusCode?: string;
  statusName?: string;
  statusDate?: string;
}

const OrderTracking: any = (props: OrderTrackingProps) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { location } = props;
  const urlParams = new URLSearchParams(location.search);

  const { loading, consignmentData, driverLocation, hubLocation, durations, wayPointsInfo, isTechIssue, orderArrivalTime } = useSelector((state: AppState) => state.common);
  const defaultLanguage = useLanguageHook();
  const language = getLanguageFromUrl(urlParams);
  const isRTL = language === "ar";

  const [status, setStatus] = useState(defaultLanguage.DELIVERY_STATUS.ORDER_PLACED);
  const [omsStatus, setOmsStatus] = useState("");
  const [statusFFStatus, setStatusFFStatus] = useState("");
  const [deliveredTime, setDeliveredTime] = useState("");
  const [updateMap, setUpdateMap] = useState(false);
  const [showDirections, setShowDirections] = useState(false);
  const [callCenterNumber, setCallCenterNumber] = useState("--");
  const [mapMarkers, setMapMarkers] = useState<any>([]);
  const [mapPlaces, setMapPlaces] = useState<any>([]);
  const [deliveryInformation, setDeliveryInformation] = useState<any>({});
  const [custInformation, setCustInformation] = useState<any>({});
  const [currentZoomLevel, setCurrentZoomLevel] = useState(13);
  const [duration, setDuration] = useState(0);
  const [arrivalTime, setArrivalTime] = useState(0);
  const [intialLoad, setIntialLoad] = useState(true);
  const [stateObject, setStateObject] = useState<any>({ location: {} });

  const mapCenter = useRef({ lat: 0, lng: 0 });
  let hubGeoLocation = useRef({ name: "Hub", icon: isCountryJordan(consignmentData) ? hyperMaxHubIcon : hubIcon, location: { lat: 0, lng: 0 } });
  let custGeoLocation = useRef({ name: "Customer", icon: isCountryJordan(consignmentData) ? hyperMaxCustomerIcon : customerIcon, location: { lat: 0, lng: 0 } });
  let driverGeoLocation = useRef({ name: "Driver", icon: { url: isCountryJordan(consignmentData) ? hyperMaxTrackerIcon : trackerIcon, rotation: 0 }, location: { lat: 0, lng: 0 } });

  const getOrderStatusData = () => {
    const payload: any = location.search ? qs.parse(location.search, { ignoreQueryPrefix: true, decoder: (c) => c }) : { code: "" };
    setStateObject({ ...stateObject, location: payload });
    dispatch(fetchOrderStatus({ consignmentCode: payload.code, carrier: "cams", source: payload?.source }));
  };

  const getDeliveryInfoField = useCallback(
    (fieldName: string) => {
      return deliveryInformation[fieldName];
    },
    [deliveryInformation]
  );

  const getConsignmentDataField = (fieldName: string) => {
    return consignmentData[fieldName];
  };

  const getArrivalTime = useCallback(() => {
    const deliverySlot = getDeliveryInfoField("deliverySlot");
    return deliverySlot ? moment(deliverySlot.endTime, AppConstants[language].DATE_FORMAT_BACKEND).format(AppConstants[language].TIME_FORMAT_UI_AM_PM) : "--";
  }, [getDeliveryInfoField, language]);

  const getAddressLine1 = useCallback(() => {
    return custInformation.address
      ? `${custInformation.address.apartment ? custInformation.address.apartment + ", " : ""}${custInformation.address.building ? custInformation.address.building + ", " : ""}${
          custInformation.address.streetName ? custInformation.address.streetName + ", " : ""
        }${custInformation.address.streetNumber ? custInformation.address.streetNumber + ", " : ""}${custInformation.address.area ? custInformation.address.area + ", " : ""}${
          custInformation.address.zoneNumber ? custInformation.address.zoneNumber : ""
        }`
      : "";
  }, [custInformation.address]);

  const getAddressLine2 = useCallback(() => {
    return custInformation.address ? `${custInformation.address.city ? custInformation.address.city + ", " : ""}${custInformation.address.countryName ? custInformation.address.countryName : ""}` : "";
  }, [custInformation.address]);

  const getAddressLine3 = useCallback(() => {
    return custInformation.address ? `Landmark : ${custInformation.address.landmark}` : "";
  }, [custInformation.address]);

  const pollDriverLocation = (carrier: string, consignmentCode: string, driverId: string, countryIsoCode: string, category: string) => {
    if (consignmentCode && carrier && countryIsoCode && category) {
      dispatch(fetchDriverLiveLocation({ carrier: carrier, consignmentCode: consignmentCode, driverId: driverId, countryIsoCode: countryIsoCode, category: category }));
    }
  };

  const deliveryStepStyleClass = useMemo(() => (isRTL ? "deliveryStepIconLeft" : "deliveryStepIconRight"), [isRTL]);
  const directionRightStyleClass = useMemo(() => (isRTL ? "directionRight" : ""), [isRTL]);

  useEffect(() => {
    let interval: any;
    if (consignmentData && consignmentData.deliveryInformation) {
      hubGeoLocation.current.icon = isCountryJordan(consignmentData) ? hyperMaxHubIcon : hubIcon;
      custGeoLocation.current.icon = isCountryJordan(consignmentData) ? hyperMaxCustomerIcon : customerIcon;
      driverGeoLocation.current.icon.url = isCountryJordan(consignmentData) ? hyperMaxTrackerIcon : trackerIcon;

      let deliveryOmsStatus = consignmentData.metadata && consignmentData.metadata.omsStatus ? consignmentData.metadata.omsStatus : "";
      let deliveryFFStatus = consignmentData.metadata && consignmentData.metadata.status ? consignmentData.metadata.status : "";
      setOmsStatus(deliveryOmsStatus);
      setStatusFFStatus(deliveryFFStatus);
      setStatus(getStatusClass(deliveryOmsStatus, defaultLanguage));
      if (intialLoad) {
        setDeliveryInformation(consignmentData.deliveryInformation);
        setCustInformation(consignmentData.deliveryInformation.customerContactInfo);
        mapCenter.current = {
          lat: parseFloat(consignmentData.deliveryInformation.customerContactInfo.address.latitude),
          lng: parseFloat(consignmentData.deliveryInformation.customerContactInfo.address.longitude),
        };
        setShowDirections(false);
        setUpdateMap(false);
      }
      if (!consignmentData.terminalStatusReached || (consignmentData.terminalStatusReached && deliveryOmsStatus !== CommonConstants.OMS_STATUS.DELIVERED)) {
        interval = setTimeout(() => {
          getOrderStatusData();
        }, 50000);
      } else {
        let omsStatus: omsStatusProps[] = consignmentData.omsStatuses;
        const filtered = omsStatus.find((o) => {
          return o.statusCode === CommonConstants.OMS_STATUS.DELIVERED;
        });
        const timezone = consignmentData?.metadata?.timeZone;
        const formattedDate = getDateStringFromUTC(filtered?.statusDate, timezone);
        const formattedTime = getFormatedDate(formattedDate);
        setDeliveredTime(formattedTime);
      }
      if (intialLoad && consignmentData.posInformation) {
        if (
          consignmentData.posInformation.contactInfo &&
          consignmentData.posInformation.contactInfo.address &&
          consignmentData.posInformation.contactInfo.address.latitude &&
          consignmentData.posInformation.contactInfo.address.longitude
        ) {
          dispatch(
            updateHubLocation({
              hubCode: consignmentData.posInformation.store ? consignmentData.posInformation.store : "Hub",
              latitude: parseFloat(consignmentData.posInformation.contactInfo.address.latitude),
              longitude: parseFloat(consignmentData.posInformation.contactInfo.address.longitude),
            })
          );
        }
      }
      setIntialLoad(false);
    }
    return () => {
      clearTimeout(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consignmentData]);

  useEffect(() => {
    if (custInformation) {
      const address = custInformation.address;
      custGeoLocation.current = {
        ...custGeoLocation.current,
        name: custInformation.name,
        location: {
          lat: address ? parseFloat(address.latitude) : 0,
          lng: address ? parseFloat(address.longitude) : 0,
        },
      };
      setMapMarkers([hubGeoLocation.current, custGeoLocation.current]);
      setMapPlaces([]);

      let countryName = address && address.countryName;
      countryName = countryName && countryName.toLowerCase();
      if (countryName === "jordan") {
        setCallCenterNumber(AppConstants[language].NEED_HELP_NUM_JORDAN);
      } else if (countryName) {
        setCallCenterNumber(AppConstants[language].NEED_HELP_NUM);
      } else {
        setCallCenterNumber("--");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custInformation]);

  useEffect(() => {
    if (hubLocation.hubCode) {
      const address = hubLocation.hubCode ? hubLocation : null;
      hubGeoLocation.current = {
        ...hubGeoLocation.current,
        name: hubLocation.hubCode,
        location: {
          lat: address ? parseFloat(address.latitude) : 0,
          lng: address ? parseFloat(address.longitude) : 0,
        },
      };
      setMapMarkers([hubGeoLocation.current, custGeoLocation.current]);
      setMapPlaces([hubGeoLocation.current, custGeoLocation.current]);
    }
    if (driverLocation.currentLocation) {
      const address = driverLocation.currentLocation;
      let prevDriverLoc = mapPlaces[0];
      driverGeoLocation.current = {
        ...driverGeoLocation.current,
        location: {
          lat: address ? parseFloat(address.latitude) : 0,
          lng: address ? parseFloat(address.longitude) : 0,
        },
      };
      if (prevDriverLoc) {
        const prevLatLng = new google.maps.LatLng(prevDriverLoc.location.lat, prevDriverLoc.location.lng);
        const nextLatLng = new google.maps.LatLng(parseFloat(address.latitude), parseFloat(address.longitude));
        const heading = google.maps.geometry.spherical.computeHeading(prevLatLng, nextLatLng);
        driverGeoLocation.current.icon.rotation = heading;
      }
      setUpdateMap(true);
      let multipleWayPoints = [];
      if (wayPointsInfo && Object.keys(wayPointsInfo).length > 0) {
        multipleWayPoints.push(driverGeoLocation.current);
        for (const property in wayPointsInfo) {
          let consignmentLatLong = {
            name: property,
            icon: isCountryJordan(consignmentData) ? hyperMaxCustomerIcon : customerIcon,
            location: {
              lat: wayPointsInfo[property].location ? parseFloat(wayPointsInfo[property].location.latitude) : 0,
              lng: wayPointsInfo[property].location ? parseFloat(wayPointsInfo[property].location.longitude) : 0,
            },
          };
          multipleWayPoints.push(consignmentLatLong);
        }
        multipleWayPoints.push(custGeoLocation.current);
        setMapPlaces(multipleWayPoints);
      } else {
        setMapPlaces([driverGeoLocation.current, custGeoLocation.current]);
      }
      setShowDirections(true);
      setMapMarkers([hubGeoLocation.current, driverGeoLocation.current, custGeoLocation.current]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hubLocation, driverLocation]);

  const handleCurrentZoomLevel = (zoomLevel: any) => {
    setCurrentZoomLevel(zoomLevel);
  };

  useEffect(() => {
    if (durations) {
      setDuration(durations + AppConstants.BUFFER_TIME);
    }
    if (orderArrivalTime) {
      setArrivalTime(orderArrivalTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [durations, orderArrivalTime]);

  useEffect(() => {
    getOrderStatusData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loader isCountryJordan={isCountryJordan(consignmentData)}></Loader>}
      <Grid container className={classes.root}>
        {!loading &&
          omsStatus !== CommonConstants.OMS_STATUS.DELIVERY_FAILED &&
          statusFFStatus !== CommonConstants.FF_STATUS.DELIVERY_FAILED &&
          omsStatus !== CommonConstants.OMS_STATUS.CANCELLED &&
          statusFFStatus !== CommonConstants.OMS_STATUS.CANCELLED &&
          isTechIssue !== true && (
            <Grid className="orderItem heading topHeader webView" item>
              <Grid container className="headingContainer webView">
                <Typography className="headingTitle">
                  {getStatusMsg(omsStatus, statusFFStatus, language, consignmentData?.deliveryInformation?.customerContactInfo?.address?.countryIsoCode)}
                </Typography>
              </Grid>
              <Grid className={`progressBar ${directionRightStyleClass}`}>
                <Grid container className="progressContainer">
                  <Grid className="progressItem progressHeader" item>
                    <Grid container className="headerContainer webView">
                      <Grid item className="headerItem">
                        <Grid className="headerItemContainer" container>
                          {isCountryJordan(consignmentData) ? (
                            <SvgIcon className="deliveryIcon placed" viewBox="0 0 20 20" component={hyperMaxOrderPlacedIcon} />
                          ) : (
                            <SvgIcon className="deliveryIcon placed" viewBox="0 0 20 20" component={orderPlacedIcon} />
                          )}
                          <Typography className="deliveryText placed">{AppConstants[language].DELIVERY_STATUS.ORDER_PLACED}</Typography>
                        </Grid>
                      </Grid>
                      <Grid item className="headerItem">
                        <Grid className="headerItemContainer" container>
                          {isCountryJordan(consignmentData) ? (
                            <SvgIcon className="deliveryIcon preparing" viewBox="0 0 20 20" component={hyperMaxOrderBeingPrepared} />
                          ) : (
                            <SvgIcon className="deliveryIcon preparing" viewBox="0 0 20 20" component={orderBeingPrepared} />
                          )}
                          <Typography className="deliveryText preparing">{AppConstants[language].DELIVERY_STATUS.ORDER_BEING_PREPARED}</Typography>
                        </Grid>
                      </Grid>
                      <Grid item className="headerItem">
                        <Grid className="headerItemContainer" container>
                          {isCountryJordan(consignmentData) ? (
                            <SvgIcon className="deliveryIcon onTheWay" viewBox="0 0 21 17" component={hyperMaxOrderOntheWayIcon} />
                          ) : (
                            <SvgIcon className="deliveryIcon onTheWay" viewBox="0 0 20 20" component={orderOntheWayIcon} />
                          )}
                          <Typography className="deliveryText onTheWay">{AppConstants[language].DELIVERY_STATUS.ORDER_ON_THE_WAY}</Typography>
                        </Grid>
                      </Grid>
                      <Grid item className="headerItem noGrow">
                        <Grid className="headerItemContainer" container>
                          <SvgIcon className="deliveryIcon delivered" viewBox="0 0 19 14" component={orderDeliveredIcon} />
                          <Typography className="deliveryText">{AppConstants[language].DELIVERY_STATUS.ORDER_DELIVERED}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container className="headerContainer webView">
                      <Grid item className="headerItem">
                        <Grid className="headerItemContainer" container>
                          <Grid
                            className={`deliveryStep ${
                              getDeliverdStatus(status, statusFFStatus, defaultLanguage)
                                ? isCountryJordan(consignmentData)
                                  ? "hyperMaxGreen"
                                  : "green"
                                : status === defaultLanguage.DELIVERY_STATUS.ORDER_PLACED ||
                                  status === defaultLanguage.DELIVERY_STATUS.ORDER_BEING_PREPARED ||
                                  status === defaultLanguage.DELIVERY_STATUS.ORDER_ON_THE_WAY
                                ? isCountryJordan(consignmentData)
                                  ? "hyperMaxBlue"
                                  : "blue"
                                : ""
                            } ${
                              status === defaultLanguage.DELIVERY_STATUS.ORDER_BEING_PREPARED ||
                              status === defaultLanguage.DELIVERY_STATUS.ORDER_ON_THE_WAY ||
                              getDeliverdStatus(status, statusFFStatus, defaultLanguage)
                                ? isRTL
                                  ? "radius2"
                                  : "radius1"
                                : ""
                            }`}
                          >
                            {/* <SvgIcon className="deliveryStepIconLeft" viewBox="0 0 5 5" component={deliveryTimelineIcon} /> */}
                            <SvgIcon className={deliveryStepStyleClass} viewBox="0 0 5 5" component={deliveryTimelineIcon} />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item className="headerItem">
                        <Grid className="headerItemContainer" container>
                          <Grid
                            className={`deliveryStep ${
                              getDeliverdStatus(status, statusFFStatus, defaultLanguage)
                                ? isCountryJordan(consignmentData)
                                  ? "hyperMaxGreen"
                                  : "green"
                                : status === defaultLanguage.DELIVERY_STATUS.ORDER_BEING_PREPARED || status === defaultLanguage.DELIVERY_STATUS.ORDER_ON_THE_WAY
                                ? isCountryJordan(consignmentData)
                                  ? "hyperMaxBlue"
                                  : "blue"
                                : ""
                            } ${
                              status === defaultLanguage.DELIVERY_STATUS.ORDER_BEING_PREPARED
                                ? isRTL
                                  ? "radius1"
                                  : "radius2"
                                : status === defaultLanguage.DELIVERY_STATUS.ORDER_ON_THE_WAY || getDeliverdStatus(status, statusFFStatus, defaultLanguage)
                                ? "radius21"
                                : ""
                            }`}
                          >
                            <SvgIcon className={deliveryStepStyleClass} viewBox="0 0 5 5" component={deliveryTimelineIcon} />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item className="headerItem">
                        <Grid className="headerItemContainer" container>
                          <Grid
                            className={`deliveryStep ${
                              getDeliverdStatus(status, statusFFStatus, defaultLanguage)
                                ? isCountryJordan(consignmentData)
                                  ? "hyperMaxGreen radius31"
                                  : "green radius31"
                                : status === defaultLanguage.DELIVERY_STATUS.ORDER_ON_THE_WAY
                                ? isCountryJordan(consignmentData)
                                  ? "hyperMaxBlue"
                                  : "blue"
                                : ""
                            } ${isRTL ? "radius1" : "radius3"}`}
                          >
                            <SvgIcon className={deliveryStepStyleClass} viewBox="0 0 5 5" component={deliveryTimelineIcon} />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item className="headerItem">
                        <Grid className="headerItemContainer" container>
                          <Grid
                            className={`deliveryStep ${getDeliverdStatus(status, statusFFStatus, defaultLanguage) ? (isCountryJordan(consignmentData) ? "hyperMaxGreen" : "green") : "radiusLast"} ${
                              isRTL ? "radius1" : "radius4"
                            }`}
                          ></Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid className="progressItem progressHeader progressHeaderTimer" item>
                    <Grid container className="headerContainer webView">
                      <Grid item className="headerItem"></Grid>
                      <Grid item className="headerItem"></Grid>
                      <Grid item className="headerItem noGrow">
                        <Grid className="headerItemContainer" container>
                          <Typography className="deliveryTextTimer">
                            {arrivalTime && omsStatus !== CommonConstants.OMS_STATUS.DELIVERED
                              ? AppConstants[language].ARRIVING_IN
                              : omsStatus !== CommonConstants.OMS_STATUS.DELIVERED
                              ? AppConstants[language].ARRIVAL_TIME
                              : AppConstants[language].ARRIVED}
                          </Typography>
                          <Typography className={`deliveryTextTimerValue`}>
                            {omsStatus == CommonConstants.OMS_STATUS.DELIVERED ? deliveredTime : arrivalTime ? arrivalTime : getArrivalTime()}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        <Grid className="orderItem map" item>
          {!loading && CommonConstants.OMS_TERMINAL_STATUS.indexOf(omsStatus) == -1 && CommonConstants.FF_TERMINAL_STATUS.indexOf(statusFFStatus) == -1 && isTechIssue !== true && (
            <Grid container className="mapContainer">
              <MapContainer
                center={mapCenter.current}
                zoom={currentZoomLevel}
                handleCurrentZoomLevel={handleCurrentZoomLevel}
                showDirections={showDirections}
                markers={mapMarkers}
                places={mapPlaces}
                updateMap={updateMap}
                status={status}
                source={stateObject?.location?.source || undefined}
              />
            </Grid>
          )}
          {(omsStatus === CommonConstants.OMS_STATUS.DELIVERED || statusFFStatus === CommonConstants.FF_STATUS.PARTIALLY_DELIVERED || statusFFStatus === CommonConstants.FF_STATUS.DELIVERED) && (
            <Grid container className="deliveredIconContainer">
              {isCountryJordan(consignmentData) ? (
                <SvgIcon className="deliveredIcon" viewBox="0 0 300 289" component={hyperMaxDeliveredIcon} />
              ) : (
                <SvgIcon className="deliveredIcon" viewBox="0 0 320 320" component={deliveredIcon} />
              )}
            </Grid>
          )}
          {(omsStatus === CommonConstants.OMS_STATUS.DELIVERY_FAILED || statusFFStatus === CommonConstants.FF_STATUS.DELIVERY_FAILED) && (
            <>
              <Grid container className="deliveryFailedContainer mobileView">
                {isCountryJordan(consignmentData) ? (
                  <SvgIcon className="deliveryFailedIcon" viewBox="0 0 300 289" component={hyperMaxDeliveryFailedIcon} />
                ) : (
                  <SvgIcon className="deliveryFailedIcon" viewBox="0 0 320 320" component={deliveryFailedIcon} />
                )}
              </Grid>
              <Grid container className="deliveryFailedTextContainer mobileView">
                <Typography className="deliveryTextFailed">{`${AppConstants[language].DELIVERY_FAILED_MSG1} ${AppConstants[language].DELIVERY_FAILED_MSG2}`}</Typography>
              </Grid>

              <Grid container className="deliveryFailedTextContainerWeb webView">
                <Typography className="deliveryTextFailedWeb">{AppConstants[language].DELIVERY_FAILED_MSG1}</Typography>
                <Typography className="deliveryTextFailedWeb1">{AppConstants[language].DELIVERY_FAILED_MSG2}</Typography>
              </Grid>
              <Grid container className="deliveryFailedContainerWeb webView">
                {isCountryJordan(consignmentData) ? (
                  <SvgIcon className="deliveryFailedIcon" viewBox="0 0 300 289" component={hyperMaxDeliveryFailedIcon} />
                ) : (
                  <SvgIcon className="deliveryFailedIcon" viewBox="0 0 320 320" component={deliveryFailedIcon} />
                )}
              </Grid>
            </>
          )}
          {(omsStatus === CommonConstants.OMS_STATUS.CANCELLED || statusFFStatus === CommonConstants.FF_STATUS.CANCELLED) && (
            <>
              <Grid container className="deliveryFailedContainer mobileView">
                {isCountryJordan(consignmentData) ? (
                  <SvgIcon className="deliveryFailedIcon" viewBox="0 0 320 289" component={hyperMaxCancelledIcon} />
                ) : (
                  <SvgIcon className="deliveryFailedIcon" viewBox="0 0 320 320" component={cancelledIcon} />
                )}
              </Grid>
              <Grid container className="deliveryFailedTextContainer mobileView">
                <Typography className="deliveryTextFailed ">{AppConstants[language].ORDER_CANCELLED_MSG1}</Typography>
                <Typography className="deliveryTextFailed deliveryTextFailed1">{AppConstants[language].ORDER_CANCELLED_MSG2}</Typography>
              </Grid>

              <Grid container className="deliveryFailedTextContainerWeb webView">
                <Typography className="deliveryTextFailedWeb orderCancelled">{AppConstants[language].ORDER_CANCELLED_MSG1}</Typography>
                <Typography className="deliveryTextFailedWeb1 orderCancelled">{AppConstants[language].ORDER_CANCELLED_MSG2}</Typography>
              </Grid>
              <Grid container className="deliveryFailedContainerWeb webView">
                {isCountryJordan(consignmentData) ? (
                  <SvgIcon className="deliveryFailedIcon" viewBox="0 0 320 289" component={hyperMaxCancelledIcon} />
                ) : (
                  <SvgIcon className="deliveryFailedIcon" viewBox="0 0 320 320" component={cancelledIcon} />
                )}
              </Grid>
            </>
          )}
          {CommonConstants.OMS_TERMINAL_STATUS.indexOf(omsStatus) == -1 && CommonConstants.FF_TERMINAL_STATUS.indexOf(statusFFStatus) == -1 && isTechIssue === true && (
            <>
              <Grid container className="deliveryFailedContainer mobileView">
                {isCountryJordan(consignmentData) ? (
                  <SvgIcon className="deliveryFailedIcon" viewBox="0 0 320 289" component={hyperMaxTechnicalIssueIcon} />
                ) : (
                  <SvgIcon className="deliveryFailedIcon technicalIssue" viewBox="0 0 320 320" component={technicalIssueIcon} />
                )}
              </Grid>
              <Grid container className="deliveryFailedTextContainer mobileView">
                <Typography className="deliveryTextFailed">{`${AppConstants[language].TECH_ISSUE_MSG1} ${AppConstants[language].TECH_ISSUE_MSG2}`}</Typography>
              </Grid>

              <Grid container className="deliveryFailedTextContainerWeb webView">
                <Typography className="deliveryTextFailedWeb">{AppConstants[language].TECH_ISSUE_MSG1}</Typography>
                <Typography className="deliveryTextFailedWeb1">{AppConstants[language].TECH_ISSUE_MSG2}</Typography>
              </Grid>
              <Grid container className="deliveryFailedContainerWeb webView">
                {isCountryJordan(consignmentData) ? (
                  <SvgIcon className="deliveryFailedIcon" viewBox="0 0 320 289" component={hyperMaxTechnicalIssueIcon} />
                ) : (
                  <SvgIcon className="deliveryFailedIconWeb" viewBox="0 0 320 320" component={technicalIssueIcon} />
                )}
              </Grid>
            </>
          )}
          {/* {duration ? 
                <Grid className="orderItem duration durationInfo webView">
                    <Grid container className="descContainer">
                        <Grid item className="descItem delivery">
                            <Grid container className="orderDescContainer">
                                <Grid item className="orderDescItem">
                                    <Typography className="name">Arriving in <span className="bold minutes">{duration} mins</span></Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                : null} */}
        </Grid>
        {!loading &&
          omsStatus !== CommonConstants.OMS_STATUS.DELIVERY_FAILED &&
          statusFFStatus !== CommonConstants.FF_STATUS.DELIVERY_FAILED &&
          omsStatus !== CommonConstants.OMS_STATUS.CANCELLED &&
          statusFFStatus !== CommonConstants.FF_STATUS.CANCELLED &&
          isTechIssue !== true && (
            <Grid className="orderItem heading bottomHeading mobileView" item>
              <Grid className="progressBar">
                <Grid container className="progressContainer">
                  <Grid className="progressItem progressHeader" item>
                    <Grid container className={`headerContainer mobileView ${directionRightStyleClass}`}>
                      {status === defaultLanguage.DELIVERY_STATUS.ORDER_PLACED ? (
                        isCountryJordan(consignmentData) ? (
                          <SvgIcon className="deliveryIcon placed" viewBox="0 0 20 20" component={hyperMaxOrderPlacedIcon} />
                        ) : (
                          <SvgIcon className="deliveryIcon placed" viewBox="0 0 20 20" component={orderPlacedIcon} />
                        )
                      ) : status === defaultLanguage.DELIVERY_STATUS.ORDER_BEING_PREPARED ? (
                        isCountryJordan(consignmentData) ? (
                          <SvgIcon className="deliveryIcon preparing" viewBox="0 0 20 20" component={hyperMaxOrderBeingPrepared} />
                        ) : (
                          <SvgIcon className="deliveryIcon preparing" viewBox="0 0 20 20" component={orderBeingPrepared} />
                        )
                      ) : status === defaultLanguage.DELIVERY_STATUS.ORDER_ON_THE_WAY ? (
                        isCountryJordan(consignmentData) ? (
                          <SvgIcon className="deliveryIcon onTheWay" viewBox="0 0 21 17" component={hyperMaxOrderOntheWayIcon} />
                        ) : (
                          <SvgIcon className="deliveryIcon onTheWay" viewBox="0 0 20 20" component={orderOntheWayIcon} />
                        )
                      ) : status === defaultLanguage.DELIVERY_STATUS.ORDER_DELIVERED ? (
                        <SvgIcon className="deliveryIcon delivered" viewBox="0 0 19 14" component={orderDeliveredIcon} />
                      ) : null}
                      <Typography
                        className={`deliveryText ${
                          status === defaultLanguage.DELIVERY_STATUS.ORDER_PLACED
                            ? "placed"
                            : status === defaultLanguage.DELIVERY_STATUS.ORDER_BEING_PREPARED
                            ? "preparing"
                            : status === defaultLanguage.DELIVERY_STATUS.ORDER_ON_THE_WAY
                            ? "onTheWay"
                            : status === defaultLanguage.DELIVERY_STATUS.ORDER_DELIVERED
                            ? "delivered"
                            : ""
                        }`}
                      >
                        {getStatusMobile(status, language, defaultLanguage, statusFFStatus)}
                      </Typography>
                      <Typography className={`mobileTimerText ${isRTL ? "mobileTimerMarginRtl" : ""}`}>
                        {arrivalTime ? AppConstants[language].ARRIVING_IN : omsStatus !== CommonConstants.OMS_STATUS.DELIVERED ? AppConstants[language].ARRIVAL_TIME : AppConstants[language].ARRIVED}
                      </Typography>
                    </Grid>
                    <Grid container className="headerContainer mobileView">
                      <Typography className={`mobileTimerValue ${isRTL ? "mobileTimerMarginRtl" : ""}`}>
                        {omsStatus == CommonConstants.OMS_STATUS.DELIVERED ? deliveredTime : arrivalTime ? arrivalTime : getArrivalTime()}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid className={`progressItem progressTimeline ${directionRightStyleClass}`} item>
                    <Grid container className="headerContainer">
                      <Grid item className="headerItem">
                        <Grid className="headerItemContainer" container>
                          <Grid
                            className={`deliveryStep ${
                              getDeliverdStatus(status, statusFFStatus, defaultLanguage)
                                ? isCountryJordan(consignmentData)
                                  ? "hyperMaxGreen"
                                  : "green"
                                : status === defaultLanguage.DELIVERY_STATUS.ORDER_PLACED ||
                                  status === defaultLanguage.DELIVERY_STATUS.ORDER_BEING_PREPARED ||
                                  status === defaultLanguage.DELIVERY_STATUS.ORDER_ON_THE_WAY
                                ? isCountryJordan(consignmentData)
                                  ? "hyperMaxBlue"
                                  : "blue"
                                : ""
                            } ${
                              status === defaultLanguage.DELIVERY_STATUS.ORDER_BEING_PREPARED ||
                              status === defaultLanguage.DELIVERY_STATUS.ORDER_ON_THE_WAY ||
                              getDeliverdStatus(status, statusFFStatus, defaultLanguage)
                                ? isRTL
                                  ? "radius2"
                                  : "radius1"
                                : ""
                            }`}
                          >
                            {/* <SvgIcon className="deliveryStepIconLeft" viewBox="0 0 5 5" component={deliveryTimelineIcon} /> */}
                            <SvgIcon className={deliveryStepStyleClass} viewBox="0 0 5 5" component={deliveryTimelineIcon} />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item className="headerItem">
                        <Grid className="headerItemContainer" container>
                          <Grid
                            className={`deliveryStep ${
                              getDeliverdStatus(status, statusFFStatus, defaultLanguage)
                                ? isCountryJordan(consignmentData)
                                  ? "hyperMaxGreen"
                                  : "green"
                                : status === defaultLanguage.DELIVERY_STATUS.ORDER_BEING_PREPARED || status === defaultLanguage.DELIVERY_STATUS.ORDER_ON_THE_WAY
                                ? isCountryJordan(consignmentData)
                                  ? "hyperMaxBlue"
                                  : "blue"
                                : ""
                            } ${
                              status === defaultLanguage.DELIVERY_STATUS.ORDER_BEING_PREPARED
                                ? isRTL
                                  ? "radius1"
                                  : "radius2"
                                : status === defaultLanguage.DELIVERY_STATUS.ORDER_ON_THE_WAY || getDeliverdStatus(status, statusFFStatus, defaultLanguage)
                                ? "radius21"
                                : ""
                            }`}
                          >
                            <SvgIcon className={deliveryStepStyleClass} viewBox="0 0 5 5" component={deliveryTimelineIcon} />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item className="headerItem">
                        <Grid className="headerItemContainer" container>
                          <Grid
                            className={`deliveryStep ${
                              getDeliverdStatus(status, statusFFStatus, defaultLanguage)
                                ? isCountryJordan(consignmentData)
                                  ? "hyperMaxGreen radius31"
                                  : "green radius31"
                                : status === defaultLanguage.DELIVERY_STATUS.ORDER_ON_THE_WAY
                                ? isCountryJordan(consignmentData)
                                  ? "hyperMaxBlue"
                                  : "blue"
                                : ""
                            } ${isRTL ? "radius1" : "radius3"}`}
                          >
                            <SvgIcon className={deliveryStepStyleClass} viewBox="0 0 5 5" component={deliveryTimelineIcon} />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item className="headerItem">
                        <Grid className="headerItemContainer" container>
                          <Grid
                            className={`deliveryStep ${getDeliverdStatus(status, statusFFStatus, defaultLanguage) ? (isCountryJordan(consignmentData) ? "hyperMaxGreen" : "green") : "radiusLast"} ${
                              isRTL ? "radius1" : "radius4"
                            }`}
                          ></Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid className="progressItem progressMsg" item>
                    <Typography className={`progressStatusMsg`}>
                      {getStatusMsg(omsStatus, statusFFStatus, language, consignmentData?.deliveryInformation?.customerContactInfo?.address?.countryIsoCode)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        {!loading && (
          <Grid
            className={`orderItem desc ${
              omsStatus === CommonConstants.OMS_STATUS.DELIVERY_FAILED || omsStatus === CommonConstants.OMS_STATUS.CANCELLED || isTechIssue === true ? "topPadding16" : ""
            } ${isCountryJordan(consignmentData) ? "backgroundForJordan" : ""}`}
            item
          >
            <Grid container className={`descContainer ${directionRightStyleClass}`}>
              <Grid item className="descItem order">
                <Grid container className="orderContainer">
                  <Typography className="orderContainerItem orderNo">{AppConstants[language].ORDER_NUMBER}</Typography>
                  {(omsStatus === CommonConstants.OMS_STATUS.DELIVERY_FAILED || omsStatus === CommonConstants.OMS_STATUS.CANCELLED || isTechIssue === true) && (
                    <Typography className="orderContainerItem orderDate">{AppConstants[language].CUSTOMER_SUPPORT}</Typography>
                  )}
                </Grid>
                <Grid container className="orderContainer">
                  <Typography className="orderContainerItemValue orderNo">{getConsignmentDataField("orderCode")}</Typography>
                  {(omsStatus === CommonConstants.OMS_STATUS.DELIVERY_FAILED || omsStatus === CommonConstants.OMS_STATUS.CANCELLED || isTechIssue === true) && (
                    <Typography className="orderContainerItemValue custSupport orderDate contact help">
                      <a href={`tel: ${callCenterNumber}`}>{callCenterNumber}</a>
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid item className="descItem delivery">
                <Grid container className="orderDescContainer">
                  <Grid item className="orderDescItem">
                    <Grid container className="deliveryItemContainer">
                      <Typography className="deliveryItem">{AppConstants[language].DELIVERING_TO}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item className="orderDescItem">
                    <Typography className="address">{getAddressLine1()}</Typography>
                    <Typography className="address">{getAddressLine2()}</Typography>
                    <Typography className="address">{getAddressLine3()}</Typography>
                  </Grid>
                  <Grid item className="orderDescItem">
                    <Typography className="phone">
                      <span className="contact mobile">
                        <a href={`tel: ${custInformation.phone}`}>{custInformation.phone || ""}</a>
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default OrderTracking;
