import { StepConnector, withStyles } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { COLORS } from "config/theme/baseTheme";

export const StepperTimelineConnector = withStyles({
  alternativeLabel: {
    top: 0,
    left: 0,
    right: 0,
    "&.placed": {
      "& .MuiStepConnector-line": {
        borderColor: COLORS.CONCRETE,
      },
    },
    "&.preparing": {
      "& .MuiStepConnector-line": {
        borderColor: COLORS.CONCRETE,
      },
    },
    "&.onTheWay": {
      "& .MuiStepConnector-line": {
        borderColor: COLORS.CONCRETE,
      },
    },
    "&.delivered": {
      "& .MuiStepConnector-line": {
        borderColor: COLORS.PERSIAN_GREEN,
      },
    },
    "&.cancelled": {
      "& .MuiStepConnector-line": {
        borderColor: COLORS.DARK_RED,
      },
    },
  },
  active: {
    "& $line": {
      borderColor: COLORS.CONCRETE,
    },
  },
  line: {
    borderColor: COLORS.CONCRETE,
    borderTopWidth: 5,
    borderRadius: 100,
  },
})(StepConnector);

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      flexDirection: "column",
      //paddingTop: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        paddingTop: theme.spacing(0),
      },
      "& .topHeader": {
        "&.webView": {
          display: "none",
        },
        [theme.breakpoints.up("sm")]: {
          "&.webView": {
            display: "flex",
          },
        },
      },
      "& .directionRight": {
        direction: "rtl",
      },
      "& .bottomHeading": {
        "&.mobileView": {
          display: "flex",
          // '& .progressTimeline': {
          //     paddingTop: '0 !important'
          // },
          "& .headerItem": {
            width: "25%",
          },
        },
        [theme.breakpoints.up("sm")]: {
          "&.mobileView": {
            display: "none",
          },
        },
      },
      "& .orderItem": {
        "&.heading": {
          position: "relative",
        },
        "& .bold": {
          fontWeight: "600 !important",
        },
        "& .minutes": {
          float: "right",
        },
        "& .green": {
          color: `${COLORS.PERSIAN_GREEN} !important`,
        },
        "& .red": {
          color: `${COLORS.DARK_RED} !important`,
        },
        "& .contact a": {
          textDecoration: "none",
          color: theme.palette.primary.main,
        },
        "& .headingContainer": {
          padding: theme.spacing(2, 2, 8),
          flexDirection: "column",
          "& .headingTitle": {
            fontSize: 24,
            fontWeight: 600,
            color: COLORS.BLACK_LIGHT,
          },
          "& .headingSubtitle": {
            fontSize: 12,
            fontWeight: 400,
          },
          [theme.breakpoints.up("sm")]: {
            alignItems: "center",
          },
        },
        "& .progressBar": {
          position: "absolute",
          height: 88,
          width: "calc(100% - 32px)",
          backgroundColor: COLORS.WHITE,
          left: 16,
          bottom: "-42px",
          zIndex: 10,
          border: `1px solid ${COLORS.CONCRETE}`,
          borderRadius: theme.spacing(1),
          "& .progressHeaderTimer": {
            paddingTop: 8,
          },
          "& .progressContainer": {
            flexDirection: "column",
            padding: theme.spacing(1.5, 1.5, 2),
            "& .headerContainer": {
              "& .deliveryStep": {
                width: "100%",
                backgroundColor: COLORS.CONCRETE,
                //borderRadius: theme.spacing(1),
                marginTop: 8,
                height: 8,
                // '&.first': {
                //     borderRadius: theme.spacing(1,0,0,1),
                // },
                // '&.last': {
                //     borderRadius: theme.spacing(0,1,1,0),
                // },
                "&.blue": {
                  backgroundColor: COLORS.TORY_BLUE,
                  borderRadius: theme.spacing(1),
                  "&.radius1": {
                    borderRadius: theme.spacing(1, 0, 0, 1),
                  },
                  "&.radius2": {
                    borderRadius: theme.spacing(0, 1, 1, 0),
                  },
                  "&.radius21": {
                    borderRadius: theme.spacing(0, 0, 0, 0),
                  },
                  "&.radius3": {
                    borderRadius: theme.spacing(0, 1, 1, 0),
                  },
                },
                "&.green": {
                  backgroundColor: COLORS.PERSIAN_GREEN,
                  borderRadius: theme.spacing(1),
                  "&.radius1": {
                    borderRadius: theme.spacing(1, 0, 0, 1),
                  },
                  "&.radius2": {
                    borderRadius: theme.spacing(0, 1, 1, 0),
                  },
                  "&.radius21": {
                    borderRadius: theme.spacing(0, 0, 0, 0),
                  },
                  "&.radius3": {
                    borderRadius: theme.spacing(0, 1, 1, 0),
                  },
                  "&.radius31": {
                    borderRadius: theme.spacing(0, 0, 0, 0),
                  },
                  "&.radius4": {
                    borderRadius: theme.spacing(0, 1, 1, 0),
                  },
                },
                "&.hyperMaxBlue": {
                  backgroundColor: COLORS.SPROUT,
                  borderRadius: theme.spacing(1),
                  "&.radius1": {
                    borderRadius: theme.spacing(1, 0, 0, 1),
                  },
                  "&.radius2": {
                    borderRadius: theme.spacing(0, 1, 1, 0),
                  },
                  "&.radius21": {
                    borderRadius: theme.spacing(0, 0, 0, 0),
                  },
                  "&.radius3": {
                    borderRadius: theme.spacing(0, 1, 1, 0),
                  },
                },
                "&.hyperMaxGreen": {
                  backgroundColor: COLORS.APPLE,
                  borderRadius: theme.spacing(1),
                  "&.radius1": {
                    borderRadius: theme.spacing(1, 0, 0, 1),
                  },
                  "&.radius2": {
                    borderRadius: theme.spacing(0, 1, 1, 0),
                  },
                  "&.radius21": {
                    borderRadius: theme.spacing(0, 0, 0, 0),
                  },
                  "&.radius3": {
                    borderRadius: theme.spacing(0, 1, 1, 0),
                  },
                  "&.radius31": {
                    borderRadius: theme.spacing(0, 0, 0, 0),
                  },
                  "&.radius4": {
                    borderRadius: theme.spacing(0, 1, 1, 0),
                  },
                },
                "&.radiusLast": {
                  borderRadius: theme.spacing(0, 1, 1, 0),
                },
                "& .deliveryStepIconLeft": {
                  float: "left",
                  width: 7,
                  height: 8,
                },
                "& .deliveryStepIconRight": {
                  float: "right",
                  width: 7,
                  height: 8,
                },
              },
              "&.webView": {
                display: "none",
                "& .headerItem": {
                  flexGrow: 1,
                  "&.noGrow": {
                    flexGrow: 0,
                  },
                },
              },
              "& .mobileTimerText": {
                margin: "0 auto",
                marginRight: 0,
                marginTop: -21,
                fontSize: 10,
              },
              "& .mobileTimerValue": {
                fontSize: 16,
                fontWeight: 700,
                paddingTop: 4,
                margin: "0 auto",
                marginRight: 0,
                marginTop: -16,
              },
              "& .mobileTimerMarginRtl": {
                marginLeft: 0,
                marginRight: "auto",
              },
              alignItems: "center",
              "& .deliveryIcon": {
                fill: "none",
                "&.placed": {
                  width: 20,
                  height: 20,
                },
                "&.preparing": {
                  width: 20,
                  height: 20,
                },
                "&.onTheWay": {
                  width: 20,
                  height: 20,
                },
                "&.delivered": {
                  width: 20,
                  height: 20,
                },
                "&.cancelled": {
                  width: 20,
                  height: 20,
                },
              },
              "& .deliveryText": {
                fontSize: 14,
                fontWeight: 600,
                paddingLeft: theme.spacing(1),
                paddingTop: theme.spacing(0.5),
                [theme.breakpoints.between(600, 650)]: {
                  fontSize: 9,
                },
                [theme.breakpoints.between(650, 700)]: {
                  fontSize: 10,
                },
                [theme.breakpoints.between(700, 800)]: {
                  fontSize: 12,
                },
                "&.placed": {
                  color: COLORS.TEXT_PRIMARY,
                },
                "&.preparing": {
                  color: COLORS.TEXT_PRIMARY,
                },
                "&.onTheWay": {
                  color: COLORS.TEXT_PRIMARY,
                },
                "&.delivered": {
                  color: COLORS.PERSIAN_GREEN,
                },
                "&.cancelled": {
                  color: COLORS.DARK_RED,
                },
              },
              "& .deliveryTextTimer": {
                fontSize: 12,
                fontWeight: 500,
                paddingLeft: theme.spacing(1),
                paddingTop: theme.spacing(0.35),
                color: COLORS.TEXT_PRIMARY,
              },
              "& .deliveryTextTimerValue": {
                fontSize: 18,
                fontWeight: 700,
                paddingLeft: theme.spacing(1),
                color: COLORS.TEXT_PRIMARY,
              },
              [theme.breakpoints.up("sm")]: {
                "&.mobileView": {
                  display: "none",
                },
                "&.webView": {
                  display: "flex",
                },
              },
            },
            "& .progressTimeline": {
              //paddingTop: theme.spacing(1),
              "& .placed": {
                "& .MuiStepConnector-alternativeLabel": {
                  width: "100%",
                  left: "-100%",
                  "&.placed": {
                    "& .MuiStepConnector-line": {
                      borderColor: COLORS.TORY_BLUE,
                    },
                  },
                },
                "& .stepIcon": {
                  //right: "50%"
                },
              },
              "& .preparing": {
                "& .MuiStepConnector-alternativeLabel": {
                  width: "102%",
                  left: "-100%",
                  "&.preparing": {
                    "& .MuiStepConnector-line": {
                      borderColor: COLORS.TORY_BLUE,
                    },
                  },
                  "&.onTheWay": {
                    "& .MuiStepConnector-line": {
                      borderColor: COLORS.TORY_BLUE,
                    },
                  },
                },
                "& .stepIcon": {
                  //right: "50%"
                },
              },
              "& .onTheWay": {
                "& .MuiStepConnector-alternativeLabel": {
                  width: "130%",
                  left: "-100%",
                  "&.preparing": {
                    "& .MuiStepConnector-line": {
                      borderColor: COLORS.TORY_BLUE,
                    },
                  },
                  "&.onTheWay": {
                    "& .MuiStepConnector-line": {
                      borderColor: COLORS.TORY_BLUE,
                    },
                  },
                },
                "& .stepIcon": {
                  right: "70%",
                },
              },
              "& .delivered": {
                "& .MuiStepConnector-alternativeLabel": {
                  width: "170%",
                  left: "-72%",
                  "&.onTheWay": {
                    "& .MuiStepConnector-line": {
                      borderColor: COLORS.TORY_BLUE,
                    },
                  },
                },
                "& .stepIcon": {
                  right: "60%",
                },
              },
            },
            "& .progressMsg": {
              "& .progressStatusMsg": {
                fontSize: 12,
                fontWeight: 500,
                paddingTop: 10,
                textAlign: "center",
                color: COLORS.COPY,
              },
            },
            "& .progressArrivalLabel": {
              fontSize: 10,
            },
            "& .progressArrivalTime": {
              fontSize: 16,
            },
          },
        },
        "&.desc": {
          padding: theme.spacing(6.25, 2, 2),
          backgroundColor: COLORS.WHITE,
          [theme.breakpoints.up("sm")]: {
            backgroundColor: COLORS.SELAGO,
            padding: theme.spacing(2, 2, 2),
            "&.backgroundForJordan": {
              backgroundColor: COLORS.KIDNAPPER,
            },
          },
          "&.topPadding16": {
            paddingTop: 16,
          },
          "& .backgroundForJordan": {
            backgroundColor: COLORS.SPROUT,
          },
          "& .descContainer": {
            maxWidth: 391,
            margin: "0 auto",
            [theme.breakpoints.up("sm")]: {
              backgroundColor: COLORS.WHITE,
              padding: theme.spacing(1.25),
              border: `1px solid ${COLORS.CONCRETE}`,
              borderRadius: theme.spacing(1),
            },
          },
          "& .descItem": {
            width: "100%",
            "&.order": {
              paddingBottom: theme.spacing(1),
            },
            "&.delivery": {
              paddingTop: theme.spacing(2),
            },
            "& .orderDescContainer": {
              flexDirection: "column",
              "& .orderDescItem": {
                paddingBottom: theme.spacing(0.5),
                "& p": {
                  fontSize: 12,
                  paddingRight: theme.spacing(0.5),
                  color: COLORS.TEXT_PRIMARY,
                },
                "& .address": {
                  fontSize: 14,
                  fontWeight: 500,
                },
              },
            },
            "& .orderContainer": {
              padding: theme.spacing(0.5, 0, 0, 0),
              alignItems: "center",
              justifyContent: "space-between",
              "& .orderContainerItem": {
                fontSize: 12,
                fontWeight: 400,
                color: COLORS.TEXT_PRIMARY,
              },
              "& .orderContainerItemValue": {
                fontSize: 14,
                fontWeight: 600,
                color: COLORS.TEXT_PRIMARY,
                "&.custSupport": {
                  color: COLORS.PRIMARY_MAIN,
                },
              },
            },
          },
        },
        "&.map": {
          flexGrow: 1,
          "& .mapContainer": {
            width: "100%",
            height: "100%",
          },
          "& .deliveredIconContainer": {
            width: "100%",
            height: "100%",
            backgroundColor: COLORS.SECONDARY_MAIN,
          },
          "& .deliveredIcon": {
            width: 295,
            height: 320,
            position: "absolute",
            top: 0,
            bottom: "29%",
            left: 0,
            right: 0,
            margin: "auto",
            [theme.breakpoints.up("sm")]: {
              bottom: "4%",
            },
          },
          "& .deliveryFailedContainer": {
            width: "100%",
            height: "70%",
            backgroundColor: COLORS.SECONDARY_MAIN,
            "&.mobileView": {
              display: "flex",
            },
            [theme.breakpoints.up("sm")]: {
              "&.mobileView": {
                display: "none",
              },
            },
          },
          "& .deliveryFailedTextContainer": {
            width: "100%",
            height: "30%",
            backgroundColor: COLORS.SECONDARY_MAIN,
            "&.mobileView": {
              display: "flex",
            },
            [theme.breakpoints.up("sm")]: {
              "&.mobileView": {
                display: "none",
              },
            },
          },
          "& .deliveryFailedIcon": {
            width: 320,
            height: 319,
            position: "absolute",
            top: 0,
            bottom: "26%",
            left: 20,
            right: 0,
            margin: "auto",
            "&.technicalIssue": {
              left: 0,
            },
            "&.orderCancelled": {
              left: 0,
            },
          },
          "& .deliveryTextFailed": {
            width: 320,
            height: 88,
            position: "absolute",
            top: 0,
            bottom: "-35%",
            left: 0,
            right: 0,
            margin: "auto",
            fontSize: 16,
            color: COLORS.TEXT_PRIMARY,
            textAlign: "center",
            fontWeight: 500,
            lineHeight: "20px",
            "&.deliveryTextFailed1": {
              fontSize: 14,
              color: COLORS.COPY,
              bottom: "-46%",
            },
          },
          "& .deliveryFailedContainerWeb": {
            width: "100%",
            height: "70%",
            backgroundColor: COLORS.WHITE,
            "&.webView": {
              display: "none",
            },
            [theme.breakpoints.up("sm")]: {
              "&.webView": {
                display: "flex",
              },
            },
          },
          "& .deliveryFailedTextContainerWeb": {
            width: "100%",
            height: "30%",
            backgroundColor: COLORS.WHITE,
            "&.webView": {
              display: "none",
            },
            [theme.breakpoints.up("sm")]: {
              "&.webView": {
                display: "flex",
              },
            },
          },
          "& .deliveryFailedIconWeb": {
            width: 295,
            height: 295,
            position: "absolute",
            top: 0,
            bottom: "10%",
            left: 0,
            right: 0,
            margin: "auto",
          },
          "& .deliveryTextFailedWeb": {
            width: 600,
            height: 88,
            position: "absolute",
            top: 0,
            bottom: "65%",
            left: 0,
            right: 0,
            margin: "auto",
            fontSize: 23,
            color: COLORS.TEXT_PRIMARY,
            textAlign: "center",
            fontWeight: 500,
            lineHeight: "36px",
            "&.orderCancelled": {
              fontSize: 20,
            },
          },
          "& .deliveryTextFailedWeb1": {
            width: 600,
            height: 78,
            position: "absolute",
            top: 0,
            bottom: "55%",
            left: 0,
            right: 0,
            margin: "auto",
            fontSize: 18,
            color: COLORS.BLACK_LIGHT,
            textAlign: "center",
            fontWeight: "normal",
            "&.orderCancelled": {
              fontSize: 16,
            },
          },
          "& .durationInfo": {
            position: "absolute",
            width: 380,
            height: 46,
            top: "40%",
            bottom: 0,
            left: 0,
            right: 0,
            margin: "auto",
            "&.webView": {
              display: "none",
            },
            [theme.breakpoints.up("sm")]: {
              "&.webView": {
                display: "flex",
              },
            },
          },
        },
        "&.duration": {
          "& .descContainer": {},
          "& .descItem": {
            width: "100%",
            "&.order": {
              paddingBottom: theme.spacing(1),
            },
            "&.delivery": {
              backgroundColor: COLORS.WHITE,
              padding: theme.spacing(1.25),
              border: `1px solid ${COLORS.CONCRETE}`,
              borderRadius: theme.spacing(1),
              paddingTop: 16,
              height: 46,
            },
            "& .orderDescContainer": {
              flexDirection: "column",
              "& .orderDescItem": {
                paddingBottom: theme.spacing(0.5),
                "& p": {
                  fontSize: 11,
                  fontWeight: 500,
                  paddingRight: theme.spacing(0.5),
                },
              },
            },
            "& .orderContainer": {
              padding: theme.spacing(0, 1),
              alignItems: "center",
              justifyContent: "space-between",
              "& .orderContainerItem": {
                fontSize: 10,
                fontWeight: 600,
                color: COLORS.BOULDER,
              },
            },
          },
        },
        "&.help": {
          "& .helpContainer": {
            padding: theme.spacing(2),
            "& p": {
              fontSize: 11,
              fontWeight: 500,
            },
            "& .helpItem": {
              paddingRight: theme.spacing(0.5),
            },
            [theme.breakpoints.up("sm")]: {
              justifyContent: "center",
            },
          },
        },
        "& .footerContent": {
          flexDirection: "column",
          padding: theme.spacing(1, 2),
          backgroundColor: COLORS.ZEUS,
          "& .footerIcon": {
            width: 148,
            height: 60,
          },
          [theme.breakpoints.up("sm")]: {
            alignItems: "center",
          },
        },
      },
    },
    timelineStepper: {
      padding: 0,
      alignItems: "center",
      backgroundColor: "transparent",
      "& .timelineStep": {
        paddingLeft: 0,
        paddingRight: 0,
        "& .stepIcon": {
          width: 5,
          height: 5,
          position: "absolute",
          zIndex: 99,
          "& ellipse": {
            fill: COLORS.SILVER_LIGHT,
          },
          "&.placed": {
            "& ellipse": {
              fill: COLORS.TORY_BLUE,
              fillOpacity: 1,
            },
          },
          "&.preparing": {
            "& ellipse": {
              fill: COLORS.TORY_BLUE,
              fillOpacity: 1,
            },
          },
          "&.onTheWay": {
            "& ellipse": {
              fill: COLORS.TORY_BLUE,
              fillOpacity: 1,
            },
          },
          "&.delivered": {
            "& ellipse": {
              fill: COLORS.PERSIAN_GREEN,
              fillOpacity: 1,
            },
          },
          "&.cancelled": {
            "& ellipse": {
              fill: COLORS.DARK_RED,
              fillOpacity: 1,
            },
          },
        },
      },
    },
  })
);
